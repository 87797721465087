import React, { Component } from "react"

export const Context = React.createContext()

class GlobalContext extends Component {
  constructor() {
    super()
    this.interval = null
  }

  state = {
    projects: [],
    length: 0,
    active: 0,
    cycling: false,
    device: {
      desktop: true,
      tablet: false,
      mobile: false,
    },
    scrollOpacity: 0,
    mobileProjectsActive: false,
    mobileProjectsVisible: false,
  }

  componentDidMount() {
    this.setDevice()
    window.addEventListener("resize", this.setDevice)
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.setDevice)
  }

  setProjectsState = (projects, length) => this.setState({ projects, length })

  cycleActive = () => {
    this.interval = setInterval(() => this.updateActive(), 200)
    this.setState({ cycling: true })
  }

  killCycle = () => {
    clearInterval(this.interval)
    this.setState({ cycling: false })
  }

  updateActive = () => {
    const { active, length } = this.state
    const next = active === length ? 0 : active + 1
    this.setState({ active: next })
  }

  setDevice = () => {
    const width = window.innerWidth
    let device = { ...this.state.device }

    if (width >= 1021) {
      device.desktop = true
      device.tablet = false
      device.mobile = false
    }

    if (width < 1021) {
      device.desktop = false
      device.tablet = true
      device.mobile = false
    }

    if (width < 750) {
      device.desktop = false
      device.tablet = false
      device.mobile = true
    }

    this.setState({ device })
  }

  handleMobileArrowClick = close => {
    this.setState(({ mobileProjectsActive }) => ({
      mobileProjectsActive: !mobileProjectsActive,
    }))

    if (close) {
      this.setState({ mobileProjectsVisible: false })
      this.killCycle()
      return
    }

    setTimeout(() => {
      this.setState({ mobileProjectsVisible: true })
    }, 300)

    setTimeout(() => {
      this.cycleActive()
    }, 600)
  }

  render() {
    return (
      <Context.Provider
        value={{
          ...this.state,
          setProjectsState: this.setProjectsState,
          cycleActive: this.cycleActive,
          killCycle: this.killCycle,
          setDevice: this.setDevice,
          handleMobileArrowClick: this.handleMobileArrowClick,
        }}
      >
        {this.props.children}
      </Context.Provider>
    )
  }
}

export default GlobalContext
